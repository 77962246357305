@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn-primary {
    @apply 
      flex
      items-center
      justify-center
      mt-10
      block
      rounded-md
      bg-blue-500
      disabled:bg-gray-300
      px-4
      py-3
      text-center
      text-base
      font-medium
      text-white
      shadow-sm
      hover:bg-blue-600
      focus-visible:outline
      focus-visible:outline-2
      focus-visible:outline-offset-2
      focus-visible:outline-blue-600;
  }
  .input-label-primary {
    @apply
    block text-sm/6 font-medium text-gray-900 pt-2;
  }
  .input-primary {
    @apply 
      col-start-1
      row-start-1
      block
      w-full
      rounded-md
      bg-white
      py-3
      pl-4
      pr-12
      text-lg
      border
      border-gray-300
      focus:border-indigo-600
      focus:ring-1
      mb-3;
  }

  .input-primary-error {
    @apply 
    text-red-900;
    @apply 
    outline-red-300;
    @apply 
    placeholder:text-red-300;
    @apply 
    focus:outline-red-600;
  }

  .error-primary {
    @apply 
    pt-2
    pb-0
    text-sm
    text-red-600;
  }

  .heading-primary {
    @apply 
    text-3xl
    font-bold
    mb-2
    text-gray-900;
  }
}